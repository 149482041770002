exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-article-index-tsx": () => import("./../../../src/templates/article/index.tsx" /* webpackChunkName: "component---src-templates-article-index-tsx" */),
  "component---src-templates-blog-post-index-tsx": () => import("./../../../src/templates/blogPost/index.tsx" /* webpackChunkName: "component---src-templates-blog-post-index-tsx" */),
  "component---src-templates-getintouch-index-tsx": () => import("./../../../src/templates/getintouch/index.tsx" /* webpackChunkName: "component---src-templates-getintouch-index-tsx" */),
  "component---src-templates-product-index-tsx": () => import("./../../../src/templates/product/index.tsx" /* webpackChunkName: "component---src-templates-product-index-tsx" */),
  "component---src-templates-standard-index-tsx": () => import("./../../../src/templates/standard/index.tsx" /* webpackChunkName: "component---src-templates-standard-index-tsx" */),
  "component---src-templates-vertical-index-tsx": () => import("./../../../src/templates/vertical/index.tsx" /* webpackChunkName: "component---src-templates-vertical-index-tsx" */)
}

