import React, { ReactElement, createContext, useState, useContext } from 'react';

/////////////////////////////////////////////////////////////////////
// OVERLAY CONTEXT
////////////////////////////////////////////////////////////////////

export type OverlayType = ReactElement | null;
export type LayoutContextType = {
	overlay: OverlayType;
	setOverlay: (overlay: OverlayType) => void;
	theme: string;
	setTheme: (theme: string) => void;
};

const AppOverlayContext = createContext<LayoutContextType>({
	overlay: null,
	setOverlay: () => {},
	theme: 'dark',
	setTheme: () => {}
});
export const useAppOverlay = (): LayoutContextType => useContext(AppOverlayContext);

export const AppOverlayProvider = ({ children }: { children: ReactElement | ReactElement[] }): ReactElement => {
	const [overlay, setOverlay] = useState<OverlayType>(null);
	const [theme, setTheme] = useState<string>('dark');
	return (
		<AppOverlayContext.Provider value={{ overlay, setOverlay, theme, setTheme }}>{children}</AppOverlayContext.Provider>
	);
};

/////////////////////////////////////////////////////////////////////
// NAV THEME CONTEXT
////////////////////////////////////////////////////////////////////
export type NavThemeType = string | undefined;
export type NavThemeContextType = {
	theme: NavThemeType;
};

const NavThemeContext = createContext<NavThemeContextType>({ theme: undefined });
export const useNavTheme = (): NavThemeContextType => useContext(NavThemeContext);

export const NavThemeProvider = ({
	children,
	theme
}: {
	children: ReactElement | ReactElement[];
	theme: NavThemeType;
}): ReactElement => <NavThemeContext.Provider value={{ theme }}>{children}</NavThemeContext.Provider>;

/////////////////////////////////////////////////////////////////////
// APP CONTEXT
////////////////////////////////////////////////////////////////////

export interface IAppContext {
	type: string;
}

export type AppContextType = {
	pageType: string;
	location: string;
};
const AppContext = createContext<AppContextType>({ pageType: 'index', location: '/' });
export const useApp = (): AppContextType => useContext(AppContext);

export interface Props {
	children: ReactElement | ReactElement[];
	type: string;
	path: string;
}

const AppProvider = ({ children, type, path }: Props): ReactElement => {
	const [pageType] = useState(type);
	const [location] = useState(path);
	return <AppContext.Provider value={{ pageType, location }}>{children}</AppContext.Provider>;
};

export default AppContext;
export { AppProvider };
